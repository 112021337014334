<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

    <div class="card-body">

      <!-- <form [formGroup] = "formdata" (ngSubmit) = "addExerciseVideos(formdata.value)" enctype="multipart/form-data"> -->
      <form [formGroup]="formdata">
        <!-- <div class="form-group">
          <label for="pwd" class="required">Thumbnail: (H: 165px W: 121px)</label>
          <input type="file" class="form-control" id="thumbnail" (change)="uploadThumbnail($event)">
        </div> -->
        
        <div class="form-group">
          <label for="Name" class="required">Upload Video:</label>
          <input type="file" class="form-control" id="inputDuration" #fileInput formControlName='videoFile'
            name="myfile" (change)="uploadInstallerImage($event)" placeholder="Add Image" />
          <div *ngIf="uploaded"> Video has been uploaded. </div>

          <div *ngIf="submitted && f.videoFile.errors" class="invalid-feedback"> Video is required </div>
          <div *ngIf="unvalidImgTypeMsg" style="font-size: 12px; color:red">Only mp4 is allowed</div>
        </div>

        <div class="form-group">
          <label for="pwd" class="required">Category:</label>
          <select class="form-control" formControlName='categoryName'
            [ngClass]="{ 'is-invalid': submitted && f.categoryName.errors}">
            <option value="" selected>Choose Category</option>
            <option value="Beginner" beginner>Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
        </div>

        <div class="form-group">
          <label for="pwd" class="required">Name:</label>
          <input type="text" formControlName='type' class="form-control" id="inputDuration" maxlength="60"
            placeholder="Enter Name" [ngClass]="{ 'is-invalid': submitted && f.type.errors}">
        </div>

        <div class="justify-content-center d-flex mt-4">
          <div>
            <!-- *ngIf="uploaded; else elseTemplate"> -->
            <button type="submit" class="btn btn-submit mr-3"
              (click)="addExerciseVideos(formdata.value)">Submit</button>
          </div>
          <!-- <ng-template #elseTemplate>
                                  <button type="submit" class="btn btn-submit mr-3"  disabled>Submit</button>
                                 </ng-template> -->
          <button class="btn btn-danger" type="submit" routerLink="/exercise-videos">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>