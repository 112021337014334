

  <!-- <button type="button" class="btn btn-success" routerLink="/add-offers"  style="margin-bottom: 30px;">Add Offer</button> -->

  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
     <div class="card card-border">
      <div class="card-body" id = "example_wrapper">
          <table id="example"   datatable class="display">
              <thead>
                  <tr>
                      <th>Sr. No.</th>
                      <th>Type Of Subscription</th>
                      <th>User</th>
                      <th>Product</th>
                      <th>Date/time</th>
                      <th>Status</th>
                  </tr>
              </thead>
             <tbody>
              <tr *ngFor="let category of categories; let i = index">
                     <td>{{i+1}}</td>
                     <td>{{category.subscription_type?.name}} </td> 
                     <td><a href="" routerLink="/subscription-details/{{category.product?._id}}">{{category.user?.firstName}} {{category.user?.lastName}}</a></td> 
                     <td>{{category.product?.name}}</td>
                     <td>{{category.createdOn  | date :'LLLL dd, yyyy , h:m a'}}</td> 
                     <td>{{category.status}}</td> 

    
              </tr>
                
             </tbody>
            </table>
      </div>
     </div>
    </div>
  </div>