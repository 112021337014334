import { FormArray } from '@angular/forms';
import { Routes } from 'src/app/models/routes';
import { Browser } from '@syncfusion/ej2-base';
import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxBootstrapConfirmService } from 'ngx-bootstrap-confirm';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopAndProductService } from '../service/shop-and-product.service';
import { noWhitespaceValidator } from 'src/app/_validators/noWhitespaceValidator';



@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.css']
})
export class UpdateProductComponent implements OnInit {

  priceSubscribsion : string[] = ['Price for 30 days subscription', 'Price for 45 days subscription',
  'Price for 60 days subscription'];

  numbers = ["0","1","2","3","4","5","6","7","8","9","."];

  sizes: any = ['XL', 'L', 'M', 'S','6','6.5','7','7.5','8','8.5','9','9.5','10','10.5','11','12','13','14','15']

  previewImages : any[] = [];

  categories : any[] = [];

  productForm : FormGroup;

  files : any[] = [];

  submitted : boolean = false;

  baseUrl : string = environment.url;

  productId : string = '';

  routes : Routes = new Routes();
   a;

   cValue;
   currentColorIndex: number = 0;
  getColor;


  constructor(private _fb : FormBuilder, private _activateRoute : ActivatedRoute,
     private _shopAndProductService : ShopAndProductService,
     private ngxBootstrapConfirmService : NgxBootstrapConfirmService,
     private _router : Router) {


      
    this.productForm = this._fb.group({
      category : ['', Validators.required],
      name : ['', [Validators.required, Validators.maxLength(50)]],
      price : ['', Validators.required],
      description : ['', Validators.required],
      images : [[]],
      quantity: ['', Validators.required],
      size:[[]],
      colors:this._fb.array(
        [],
        [Validators.maxLength(10)]
      ),
      length: ['', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      width: ['', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      height: ['', Validators.pattern(/^\d*\.?\d+$/)],
      weight: ['', Validators.pattern(/^\d*\.?\d+$/)],
    });
  }

 ngOnInit(): void {
  this.productId = this._activateRoute.snapshot.paramMap.get('id');
   this.getProductDetailById(this.productId);
   this.getAllCategories();
 }


get value(): string {
  if (Browser.info.name === 'msie' && this.cValue.length > 7) {
      return this.cValue.substring(0, this.cValue.length - 2);
  } else {
      return this.cValue;
  }
}

@Input('value')
set value(value: string) {
  this.cValue = value;
  this.getColor.forEach(ques => {
    this.cValue = ques

});
 
}



 getProductDetailById(productId : string){
    this._shopAndProductService.getProductById(productId).subscribe((resp : any) =>{
         if(resp.status == 200){
             const product = resp.data;
             this. a = product.size
             this.productForm.controls.category.setValue(product.category._id);
             this.productForm.controls.name.setValue(product.name);
             this.productForm.controls.price.setValue(product.price);
             this.productForm.controls.description.setValue(product.description);
             this.productForm.controls.quantity.setValue(product.quantity);
             this.productForm.controls.length.setValue(product.length);
             this.productForm.controls.width.setValue(product.width);

            if (product.height !== "undefined") {
              this.productForm.controls.height.setValue(product.height);
            }

            if (product.weight !== "undefined") {
              this.productForm.controls.weight.setValue(product.weight);
            }

             product.images.forEach(image => {
                 image.image = this.baseUrl+image.image;
                 this.previewImages.push(image);
             });

             this.getColor = product.color;
              var questionIndex = -1;
              this.getColor.forEach(ques => {
                    this.cValue = ques
                   questionIndex = questionIndex+1;
                   this.addColor(ques);
         });
        }
    });
  }



  get colors() {
    return this.productForm.get("colors") as FormArray;
  }

  defaultColor='#769f76'
  addColor(ques : any) {
    if (this.getColor != null || this.getColor == ""){
      const question = this._fb.group({
        p_color: [ques],
      });
      this.colors.push(question);
    } else {
      const question = this._fb.group({
        p_color: [ ques!==undefined ? ques.p_color : this.defaultColor ],
      });
      this.colors.push(question);
    }
    this.currentColorIndex = this.colors.length - 1;
  }


removeColor(colorIndex: number) {
  this.colors.removeAt(colorIndex);
  this.updateColor()
}

updateColor() {
  if(!this.productId) return;
  
  const colorsAfter=this.colors.controls.map(color => {
    return color['controls'].p_color.value
  })

  this._shopAndProductService.updateColors(this.productId,colorsAfter).subscribe(res => {
    
  })
}

 getAllCategories(){
  this._shopAndProductService.getCategories('').subscribe((resp : any)=>{
    if(resp.status ==200){
         this.categories = resp.data;       
    }
  })
}

 get f(){
    return this.productForm?.controls;
 }

 onFileChange(event : any){
  if(event.target.files && event.target.files.length) {
    const files =event.target.files;
    if((files.length+this.previewImages.length)>8){
       alert("Max Limit of Images is 8")
    } else{
      for(var i = 0; i<files.length; i++){
        const file = files[i];
        const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload =  () => {
          const blob =  reader.result as string;
          const image = { image : blob , _id : "0"}
          this.previewImages.push(image);
        };
        this.files.push(file);
      }
     // this.productForm.controls.images.setValue(this.files);
    }  
  }
}

onlyNumber(event : KeyboardEvent){
  const key = event.key;
  const price = this.productForm.controls.price.value;
  if(key==='.')
     return String(price).indexOf(key) > -1 ? false : true;
  return this.numbers.includes(key);
}

removeImage(imageId : string, index : number){
  let options ={
    title: 'Are you sure you want to remove this Image?',
    confirmLabel: 'Yes',
    declineLabel: 'No'
  }
  this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
    if (res) {
      if(imageId=='0')
          this.files.splice(index-this.previewImages.length,1);
      this.previewImages.splice(index,1);
    }
  });
}

 updateProduct(){
     this.submitted = true;
     if(this.productForm.valid){
     const product = this.productForm.value;
     product.images = this.files;
      if(this.previewImages.length === 0 && product.images.length === 0){
          alert("Image is required");
      }else{
         this.callUpdteProjectApi(product);
      }
     }
 }

 callUpdteProjectApi(product : any){
  let existing_images = '';
  this.previewImages.forEach(image =>{
      if(image._id !='0')
           existing_images = existing_images+image._id+',';
  }); 
   product.existing_images = existing_images.substring(0, existing_images.length-1);
   this._shopAndProductService.updateProduct(this.productId, product).subscribe((response : any) =>{
       this._router.navigate([this.routes.productsByCategory+'/'+product.category]);
   });
 }

}


