<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card card-border">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-md-12 text-center">
                        <img [src]="user.profilePicture" height="200px" width="200px">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12 text-center">
                        <label class="col-md-6"> {{ user.name }} </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Mobile</label>
                            <p class="col-md-6"> {{ user.mobileNumber }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Email</label>
                            <p class="col-md-6"> {{ user.email }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Speciality</label>
                            <p class="col-md-6"> {{ user.speciality }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Experience</label>
                            <p class="col-md-6"> {{ user.experience }} Years</p>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">City</label>
                            <p class="col-md-6"> {{ user.city }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Zip Code</label>
                            <p class="col-md-6"> {{ user.zipCode ? user.zipCode :'N/A'  }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Country</label>
                            <p class="col-md-6"> {{ user.country ? user.country : 'N/A' }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Languages</label>
                            <p class="col-md-6"> {{ user.languages }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Accept Patients</label>
                            <p class="col-md-6"> {{ user.acceptPatients ? 'Yes' : 'No' }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Bio</label>
                            <p class="col-md-6"> {{ user.bio }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Status</label>
                            <p class="col-md-6"> {{ user.admin_status }} </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row" *ngIf="user.admin_status=='Rejected'">
                            <label class="col-md-6">Rejection Reason</label>
                            <p class="col-md-6"> {{ user.rejection_reason }} </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Verification Document 1</label>
                            <p class="col-md-6" *ngIf="user.document && user.document!='undefined'"><a [href]="user.document" target="_new">View</a></p>
                            <p class="col-md-6" *ngIf="!user.document || user.document=='undefined'">N/A</p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-6">Verification Document 2</label>
                            <p class="col-md-6" *ngIf="user.license && user.license!='undefined'"><a [href]="user.license" target="_new">View</a></p>
                            <p class="col-md-6" *ngIf="!user.license || user.document=='undefined'">N/A</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>