import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PostService } from '../services/post.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-flagged-comments',
  templateUrl: './flagged-comments.component.html',
  styleUrls: ['./flagged-comments.component.css']
})
export class FlaggedCommentsComponent implements OnInit {

  webUrl=environment.web
  urlAppend='big-me-details'
  comments=[]
  pagination = { totalItems:0,itemsPerPage:10,currentPage:1 }
  constructor(
    private postService:PostService,
    private snackbarService:SnackbarService
  ) { }

  ngOnInit(): void {
    this.getComments()
  }

  getComments() {
      this.postService.getComments(this.pagination.currentPage -1).subscribe((response:any) => {
        this.comments=response.data;
        this.pagination.totalItems=response.totalRecords
      })
  }

  showContent(content) {
    this.snackbarService.show(content,'')
  }

   transformMentions(input) {
    // Regular expression to match mentions in the format @[name](id)
    const mentionRegex = /\((.+?)\)/g;
   
    // Replace mentions with the desired format
    const firstInput = input.replace(mentionRegex, (_, name) => ``);

    return firstInput.replace(/\[([^\]]+)\]/g, '$1');
}
  deleteComment(comment) {
    this.snackbarService.alertWithConfirmations("Are you sure you want to delete this comment?").then(response => {
      if (response.isConfirmed) {
        this.postService.deleteComment(comment.comment_id._id).subscribe(() => {
          this.snackbarService.successSnackBar("Comment deleted successfully", null)
          this.pagination.currentPage = 1//get back to first page
          this.getComments()
        })
      }
    })
  }

  onPageChange(page: any) {
    this.pagination.currentPage = page
    this.getComments();
  }

}
