import 'rxjs/add/operator/map';
import { Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../services/snackbar.service';
import { FormGroup, FormControl,Validators } from '@angular/forms';

@Component({
   selector: 'app-add-exercise',
  templateUrl: './add-exercise.component.html',
  styleUrls: ['./add-exercise.component.css']
})
export class AddExerciseComponent implements OnInit {

formdata;
exerciseName;
timeDuration;
submitted;
formdetail;
invalid:boolean  = false;

apiBaseUrl : string = environment.url;


    constructor(private snackbar: SnackbarService,private http: HttpClient, private router: Router) { }

  ngOnInit() {
  this.formdata = new FormGroup({
        exerciseName: new FormControl("",[Validators.required]),
         timeDuration: new FormControl("",[Validators.required])
      });

}

  get f() { return this.formdata.controls; }

   addExercise(data) {
      this.submitted = true;         
        if (this.formdata.invalid) {
            return;
        }
         this.formdetail = {
             'exerciseName':data.exerciseName,
             'timeDuration':data.timeDuration,
             //'thumbnail':this.thumbnail
         } 
    
    console.log(this.formdetail)

    this.http.post(this.apiBaseUrl+'v1/addExerciseAdmin',this.formdetail).subscribe((data : any) => {
     if(data.status == 200){ 
      this.snackbar.successSnackBar(" Exercise added successfully.",'');
       this.router.navigate(['/exercises']);
      }     
    else this.invalid = true;
     })
     }

     thumbnail : any
     thumbnailSrc : any
     invalidThumbnail = false
     @ViewChild('thumbnail') thumbnailElement :ElementRef
     uploadThumbnail(event) {

      const fileName: any = event.target.files[0];
      this.thumbnail = event.target.files[0];  
      const idxDot = event.target.files[0].name.lastIndexOf(".") + 1;
      const extFile = event.target.files[0].name.substr(idxDot, event.target.files[0].name.length).toLowerCase();
      const filesize = fileName.size / 1024
      if (filesize > 100) {
        alert("Please provide video with less then 100 Kb.");
        return;
      }
  
      if (extFile == "png") {
        const reader = new FileReader();
        reader.onload = e => this.thumbnailSrc = reader.result;
        reader.readAsDataURL(fileName);
        this.invalidThumbnail = false;
      } else {
        this.thumbnailElement.nativeElement.value=''
        this.invalidThumbnail = true;
      }
    }

numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


}
