<!doctype html>
<html lang="en">
  <head>
    <title>BiG4 Health</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap" rel="stylesheet">
    <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.10.20/css/jquery.dataTables.min.css">
   
    
  </head>
  <body>

   <!-- <app-navbar></app-navbar> -->

    <div class="page-wrapper">
       <div class="container">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          
            <div class="card-body">

                 <form [formGroup] = "formdata" (ngSubmit) = "editExerciseByAdmin(formdata.value)" enctype="multipart/form-data">
                  <div class="form-group">
                    <label for="Name">Exercise Name:</label>
                    <input type="text"  formControlName='exerciseName' value= "{{exerciseName}}" class="form-control" id="inputName" placeholder="Enter Exercise Name" name="Name" [ngClass]="{ 'is-invalid': submitted && f.exerciseName.errors}">
                  </div>
                  <div class="form-group">
                    <label for="pwd">Duration:</label>
                    <input type="number" maxlength="3" formControlName='timeDuration'  min="1"  (keypress)="numberOnly($event)"    value= "{{timeDuration}}"  class="form-control" id="inputDuration" placeholder="Minutes" name="duration" [ngClass]="{ 'is-invalid': submitted && f.timeDuration.errors}">
                  </div>
                  <!-- <div class="form-group">
                    <label for="pwd" class="required">Thumbnail: (H: 165px W: 121px)</label>
                    <input type="file" class="form-control" id="thumbnail" (change)="uploadThumbnail($event)">
                  </div> -->
                  <div class="justify-content-center d-flex mt-4">
                  <button type="submit" class="btn btn-submit mr-3">Update</button>
                  <button routerLink="/exercises" class="btn btn-info">Cancel</button>
                  </div>
                </form>
            </div>
           
          </div>
        </div>
       </div>
    </div>



<script>
function openNav() {
  document.getElementById("mySidenav").style.width = "250px";
}

function closeNav() {
  document.getElementById("mySidenav").style.width = "0";
}
</script>



  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>
  <script src="https://cdn.datatables.net/1.10.20/js/jquery.dataTables.min.js"></script>
  <Script>
    $(document).ready( function () {
    $('#myTable').DataTable();
} );
  </Script>
  </body>
</html>