import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// import { DataTableModule } from 'ng-angular8-datatable';
import { ExerciseVideosComponent } from './exercise-videos/exercise-videos.component';
import { WorkoutChallengesComponent } from './workout-challenges/workout-challenges.component';
import { AddVideoComponent } from './add-video/add-video.component';
import { AddWorkoutChallengeComponent } from './add-workout-challenge/add-workout-challenge.component';
import { ExercisesComponent } from './exercises/exercises.component';
import { AddExerciseComponent } from './add-exercise/add-exercise.component';
import { EditExerciseComponent } from './edit-exercise/edit-exercise.component';
import { StressManagementComponent } from './stress-management/stress-management.component';
import { AddSoundComponent } from './add-sound/add-sound.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditChallengeComponent } from './edit-challenge/edit-challenge.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BillingTermsComponent } from './billing-terms/billing-terms.component';
import { AddMedicationComponent } from './medication/add-medication/add-medication.component';
import { AddQuizComponent } from './quiz/add-quiz/add-quiz.component';
import { QuizComponent } from './quiz/quiz/quiz.component';
import { UpdateQuizComponent } from './quiz/update-quiz/update-quiz.component';
import { MedicationComponent } from './medication/medication/medication.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatChipsModule} from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from "@angular/material/button";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { MatTooltipModule } from "@angular//material/tooltip";
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { SortPipe } from './pipes/sort/sort.pipe';
import { MaterialNavbarComponent } from './material-navbar/material-navbar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatDividerModule} from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { AuthGuard } from './auth/auth.guard';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LoaderComponent } from './common/loader/loader.component';
import { InterceptorInterceptor } from './interceptor/interceptor.interceptor';
import { CategoryComponent } from './eLearning/category/category.component';
import { AssignVideoToCategoryComponent } from './eLearning/assign-video-to-category/assign-video-to-category.component';
import { CategoryDialogComponent } from './eLearning/category-dialog/category-dialog.component';
import { MatExpansionModule} from '@angular/material/expansion';
import { AddELearningVideoComponent } from './eLearning/add-elearning-video/add-elearning-video.component';
import { ShopCategoriesComponent } from './shopAndProductManagement/shop-categories/shop-categories.component';
import { AddOrUpdateShopCategoryComponent } from './shopAndProductManagement/add-or-update-shop-category/add-or-update-shop-category.component';
import { ProductUnderCategoryComponent } from './shopAndProductManagement/product-under-category/product-under-category.component';
import { AddProductComponent } from './shopAndProductManagement/add-product/add-product.component';
import { ViewAndUpdateProductComponent } from './shopAndProductManagement/view-and-update-product/view-and-update-product.component';
import { OrdersComponent } from './order/orders/orders.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { CreateBlogComponent } from './blog/create-blog/create-blog.component';
import { QuillModule } from 'ngx-quill';
import { BlogsComponent } from './blog/blogs/blogs.component';
import { UpdateBlogComponent } from './blog/update-blog/update-blog.component';
import { NgxBootstrapConfirmModule } from 'ngx-bootstrap-confirm';
import { UpdateProductComponent } from './shopAndProductManagement/update-product/update-product.component';
import { MaxLimitDirective } from './directive/max-limit.directive';
import { AllvideosComponent } from './eLearning/allvideos/allvideos.component';
import { ReportManagementComponent } from './report-management/report-management.component';
import { BannerManagementComponent } from './banner-management/banner-management.component';
import { AddBannerComponent } from './banner-management/add-banner/add-banner.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { OffersManagementComponent } from './offers-management/offers-management.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { ChargesManagementComponent } from './charges-management/charges-management.component';
import { EmailManagementComponent } from './email-management/email-management.component';
import { AddOffersComponent } from './offers-management/add-offers/add-offers.component';
import { UpdateOffersComponent } from './offers-management/update-offers/update-offers.component';
import { CreateEmailComponent } from './email-management/create-email/create-email.component';
import { ActiveOrdersComponent } from './order/active-orders/active-orders.component';
import { CompletedOrdersComponent } from './order/completed-orders/completed-orders.component';
import { CancelOrdersComponent } from './order/cancel-orders/cancel-orders.component';
import { EditChargesComponent } from './charges-management/edit-charges/edit-charges.component';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { UpdateElearningVideoComponent } from './eLearning/update-elearning-video/update-elearning-video.component';
import { AdminAddressComponent } from './admin-address/admin-address.component';
import { AddAddressComponent } from './admin-address/add-address/add-address.component';
import { UserSubscriptionDetailComponent } from './subscription/user-subscription-detail/user-subscription-detail.component';
import { RefundComponent } from './order/order-detail/refund/refund.component';
import { ReturnOrdersComponent } from './order/return-orders/return-orders.component';
import { PremiumUsersComponent } from './premium-users/premium-users.component';
import { NgChartjsModule } from 'ng-chartjs';
import { ChartsModule } from "@rinminase/ng-charts";
import { QuizRewardComponent } from './quiz/quiz-reward/quiz-reward.component';
import { UpdateRewardComponent } from './quiz/quiz-reward/update-reward/update-reward.component';
import { NotificationComponent } from './notification/notification.component';
import { LogoCertificationComponent } from './logo/logo-certification/logo-certification.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { HealthDataComponent } from './health-data/health-data.component';
import { ViewSentEmailComponent } from './email-management/view-sent-email/view-sent-email.component';
import { ProfessionalAccountsComponent } from './all-users/professional-accounts/professional-accounts.component';
import { PostsComponent } from './posts/posts.component';
import { TrustedLogoComponent } from './trusted-logo/trusted-logo.component';
import { HomeComponent } from './home/home.component';
import { MePostsComponent } from './posts/me-posts/me-posts.component';
import { MedPostsComponent } from './posts/med-posts/med-posts.component';
import { WorldPostsComponent } from './posts/world-posts/world-posts.component';
import { UserDetailComponent } from './all-users/user-detail/user-detail.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WorldPostDetailsComponent } from './posts/world-post-details/world-post-details.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';
import { MdPostsComponent } from './md-posts/md-posts.component';
import { UpcomingRequestsComponent } from './md-posts/upcoming-requests/upcoming-requests.component';
import { CompletedRequestComponent } from './md-posts/completed-request/completed-request.component';
import { CancelledRequestComponent } from './md-posts/cancelled-request/cancelled-request.component';
import { FlaggedCommentsComponent } from './flagged-comments/flagged-comments.component';
// import { Angular2CsvModule } from 'angular2-csv';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,   
    AllUsersComponent, NavbarComponent, ExerciseVideosComponent, WorkoutChallengesComponent,
     AddVideoComponent, AddWorkoutChallengeComponent, ExercisesComponent,
      AddExerciseComponent, EditExerciseComponent, StressManagementComponent, 
      AddSoundComponent, AddUserComponent, EditChallengeComponent, DashboardComponent, 
      FeedbackComponent, TermsComponent, PolicyComponent, CopyrightComponent,
       AboutUsComponent, BillingTermsComponent, 
       MedicationComponent, AddMedicationComponent,
        AddQuizComponent, QuizComponent, UpdateQuizComponent, FilterPipe, SortPipe,
         MaterialNavbarComponent, LoaderComponent, CategoryComponent, AssignVideoToCategoryComponent,
          CategoryDialogComponent,
          AddELearningVideoComponent,
          ShopCategoriesComponent,
          AddOrUpdateShopCategoryComponent,
          ProductUnderCategoryComponent,
          AddProductComponent,
          ViewAndUpdateProductComponent,
          OrdersComponent,
          OrderDetailComponent,
          CreateBlogComponent,
          BlogsComponent,
          UpdateBlogComponent,
          UpdateProductComponent,
          MaxLimitDirective,
          AllvideosComponent,
          ReportManagementComponent,
          BannerManagementComponent,
          AddBannerComponent,
          SnackbarComponent,
          OffersManagementComponent,
          TransactionHistoryComponent,
          ChargesManagementComponent,
          EmailManagementComponent,
          AddOffersComponent,
          UpdateOffersComponent,
          CreateEmailComponent,
          ActiveOrdersComponent,
          CompletedOrdersComponent,
          CancelOrdersComponent,
          EditChargesComponent,
          ColorPickerComponent,
          SubscriptionComponent,
          UpdateElearningVideoComponent,
          AdminAddressComponent,
          AddAddressComponent,
          UserSubscriptionDetailComponent,
          RefundComponent,
          ReturnOrdersComponent,
          PremiumUsersComponent,
          QuizRewardComponent,
          UpdateRewardComponent,
          NotificationComponent,
          LogoCertificationComponent,
          TestimonialsComponent,
          HealthDataComponent,
          ViewSentEmailComponent,
          ProfessionalAccountsComponent,
          PostsComponent,
          TrustedLogoComponent,
          HomeComponent,
          MePostsComponent,
          MedPostsComponent,
          WorldPostsComponent,
          UserDetailComponent,
          ContactUsComponent,
          WorldPostDetailsComponent,
          MdPostsComponent,
          UpcomingRequestsComponent,
          CompletedRequestComponent,
          CancelledRequestComponent,
          FlaggedCommentsComponent
  ],
  imports: [
    // Angular2CsvModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatChipsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSidenavModule,
    LayoutModule,
    MatToolbarModule,
    MatListModule,
    MatDividerModule,
    GooglePlaceModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    NgxUsefulSwiperModule,
    NgxBootstrapConfirmModule,
    ColorPickerModule,
    NgChartjsModule,
    ChartsModule,
    NgxPaginationModule,


    QuillModule.forRoot({
      customOptions: [{
        import: 'formats/font',
        whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace']
      }]
    }),
 
  ],
  providers: [AuthGuard,  {
     provide : HTTP_INTERCEPTORS,
     useClass : InterceptorInterceptor,
     multi : true
  }],
  bootstrap: [AppComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
