export class Routes {
    login : string = ''
    dashboard : string = 'dashboard'
    allUsers : string = 'all-users'
    exercises : string = 'exercises'
    exerciseVideos : string = 'exercise-videos'
    stressManagement : string = 'stress-management'
    workoutChallenges : string = 'workout-challenges'

    feedback : string = 'feedback'
    address : string = 'address'

    quiz : string = 'quiz'
    addQuiz : string = 'add_quiz'
    updateQuiz : string = 'update_quiz'
    medication : string = 'medication'

    eLearningCategories : string = 'e-learning/categories'
    eLearningVideos : string = 'e-learning/videos'
    eLearningAllVideos : string = 'e-learning/Allvideos'

    shopCategories : string = 'shop/categories'
    productsByCategory : string ="products"
    viewupdateProduct : string ="view/product"
    addProduct : string = 'add_product'
    updateProduct : string = 'update_product'
    
    order : string = 'order'
    orderDetails : string = 'order/active/order_detail'
    orderDetails1 : string = 'order/past/order_detail'
    orderDetails2 : string = 'order/cancel/order_detail'
    orderDetails3 : string = 'order/return/order_detail'
    createBlog : string = 'create/blog'
    updateBlog : string = 'update/blog'
    blogs : string = 'blogs'
    report : string = 'Report'
    banner : string = 'banner'
    offers : string = 'offers'
    transaction:string = 'transaction'
    charges:string = 'charges'
    createEmail : string = 'create/Email'
    viewSentMail : string = 'view-sent-mail'
    Emails : string = 'Emails'
    activeOrders : string = 'order/active'
    pastOrders : string = 'order/past'
    cancelOrders : string = 'order/cancel'
    returnOrders : string = 'order/return'
    subscription : string = 'subscription'
    premium:string = 'premium-user'
    quiz_rewards:string = ''

    med_posts='posts/med'
    me_posts='posts/me'
    world_posts='posts/world'
    flagged_comments='comments/flagged'
    contact_us='contact_us'
    home ='home'
    health_data='health_data'
    testimonials='testimonials'
    certification_logo='certification_logo'
    trusted_logo='trusted_logo'
    professional_accounts='professional_accounts'

    md_upcoming='md/upcoming'
    md_completed='md/completed'
    md_cancelled='md/cancelled'



}
