import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { ExerciseVideosComponent } from './exercise-videos/exercise-videos.component';
import { WorkoutChallengesComponent } from './workout-challenges/workout-challenges.component';
import { AddVideoComponent } from './add-video/add-video.component';
import { AddWorkoutChallengeComponent } from './add-workout-challenge/add-workout-challenge.component';
import { ExercisesComponent } from './exercises/exercises.component';
import { AddExerciseComponent } from './add-exercise/add-exercise.component';
import { EditExerciseComponent } from './edit-exercise/edit-exercise.component';
import { StressManagementComponent } from './stress-management/stress-management.component';
import { AddSoundComponent } from './add-sound/add-sound.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { EditChallengeComponent } from './edit-challenge/edit-challenge.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { AboutUsComponent } from './about-us/about-us.component';

import { BillingTermsComponent } from './billing-terms/billing-terms.component';
import { MedicationComponent } from './medication/medication/medication.component';
import { QuizComponent } from './quiz/quiz/quiz.component';
import { AddQuizComponent } from './quiz/add-quiz/add-quiz.component';
import { UpdateQuizComponent } from './quiz/update-quiz/update-quiz.component';
import { AuthGuard } from './auth/auth.guard';
import { CategoryComponent } from './eLearning/category/category.component';
import { AssignVideoToCategoryComponent } from './eLearning/assign-video-to-category/assign-video-to-category.component';
import { ShopCategoriesComponent } from './shopAndProductManagement/shop-categories/shop-categories.component';
import { ProductUnderCategoryComponent } from './shopAndProductManagement/product-under-category/product-under-category.component';
import { ViewAndUpdateProductComponent } from './shopAndProductManagement/view-and-update-product/view-and-update-product.component';
import { AddProductComponent } from './shopAndProductManagement/add-product/add-product.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { CreateBlogComponent } from './blog/create-blog/create-blog.component';
import { BlogsComponent } from './blog/blogs/blogs.component';
import { UpdateBlogComponent } from './blog/update-blog/update-blog.component';
import { UpdateProductComponent } from './shopAndProductManagement/update-product/update-product.component';
import { AllvideosComponent } from './eLearning/allvideos/allvideos.component';
import { AddELearningVideoComponent } from './eLearning/add-elearning-video/add-elearning-video.component';
import { ReportManagementComponent } from './report-management/report-management.component';
import { BannerManagementComponent } from './banner-management/banner-management.component';
import { OffersManagementComponent } from './offers-management/offers-management.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { ChargesManagementComponent } from './charges-management/charges-management.component';
import { EmailManagementComponent } from './email-management/email-management.component';
import { AddOffersComponent } from './offers-management/add-offers/add-offers.component';
import { UpdateOffersComponent } from './offers-management/update-offers/update-offers.component';
import { CreateEmailComponent } from './email-management/create-email/create-email.component';
import { ActiveOrdersComponent } from './order/active-orders/active-orders.component';
import { CompletedOrdersComponent } from './order/completed-orders/completed-orders.component';
import { CancelOrdersComponent } from './order/cancel-orders/cancel-orders.component';
import { EditChargesComponent } from './charges-management/edit-charges/edit-charges.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { UpdateElearningVideoComponent } from './eLearning/update-elearning-video/update-elearning-video.component';
import { AdminAddressComponent } from './admin-address/admin-address.component';
import { AddAddressComponent } from './admin-address/add-address/add-address.component';
import { UserSubscriptionDetailComponent } from './subscription/user-subscription-detail/user-subscription-detail.component';
import { RefundComponent } from './order/order-detail/refund/refund.component';
import { ReturnOrdersComponent } from './order/return-orders/return-orders.component';
import { PremiumUsersComponent } from './premium-users/premium-users.component';
import { QuizRewardComponent } from './quiz/quiz-reward/quiz-reward.component';
import { UpdateRewardComponent } from './quiz/quiz-reward/update-reward/update-reward.component';
import { NotificationComponent } from './notification/notification.component';
import { LogoCertificationComponent } from './logo/logo-certification/logo-certification.component';
import { HealthDataComponent } from './health-data/health-data.component';
import { ViewSentEmailComponent } from './email-management/view-sent-email/view-sent-email.component';
import { ProfessionalAccountsComponent } from './all-users/professional-accounts/professional-accounts.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { TrustedLogoComponent } from './trusted-logo/trusted-logo.component';
import { HomeComponent } from './home/home.component';
import { MePostsComponent } from './posts/me-posts/me-posts.component';
import { MedPostsComponent } from './posts/med-posts/med-posts.component';
import { WorldPostsComponent } from './posts/world-posts/world-posts.component';
import { UserDetailComponent } from './all-users/user-detail/user-detail.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WorldPostDetailsComponent } from './posts/world-post-details/world-post-details.component';
import { UpcomingRequestsComponent } from './md-posts/upcoming-requests/upcoming-requests.component';
import { CompletedRequestComponent } from './md-posts/completed-request/completed-request.component';
import { CancelledRequestComponent } from './md-posts/cancelled-request/cancelled-request.component';
import { FlaggedCommentsComponent } from './flagged-comments/flagged-comments.component';


const routes: Routes = [
{ path: '', component: LoginComponent },
{ path: 'all-users', component: AllUsersComponent, canActivate : [AuthGuard] },
{ path: 'exercise-videos', component: ExerciseVideosComponent , canActivate : [AuthGuard] },
{ path: 'exercises', component: ExercisesComponent, canActivate : [AuthGuard]  },
{ path: 'workout-challenges', component: WorkoutChallengesComponent, canActivate : [AuthGuard]  },
{ path: 'add-exercise-video', component: AddVideoComponent, canActivate : [AuthGuard]  },
{ path: 'add-exercise', component: AddExerciseComponent, canActivate : [AuthGuard]  },
{ path: 'add-workout-challenge', component: AddWorkoutChallengeComponent, canActivate : [AuthGuard]  },
{ path: 'edit-exercise/:id', component: EditExerciseComponent, canActivate : [AuthGuard]  },
{ path: 'stress-management', component: StressManagementComponent, canActivate : [AuthGuard]  },
{ path: 'add-sound', component: AddSoundComponent, canActivate : [AuthGuard]  },
{ path: 'add-user', component: AddUserComponent , canActivate : [AuthGuard] },
{ path: 'edit-challenge/:id', component: EditChallengeComponent, canActivate : [AuthGuard]  },
{ path: 'dashboard', component: DashboardComponent , canActivate : [AuthGuard] },
{ path: 'feedback', component: FeedbackComponent, canActivate : [AuthGuard]  },
{ path: 'terms', component: TermsComponent, canActivate : [AuthGuard]  },
{ path: 'policy', component: PolicyComponent, canActivate : [AuthGuard]  },
{ path: 'copyright', component: CopyrightComponent , canActivate : [AuthGuard] },
{ path: 'about-us', component: AboutUsComponent, canActivate : [AuthGuard]  },
{ path: 'billing-terms', component: BillingTermsComponent, canActivate : [AuthGuard]  },
{ path : 'medication', component : MedicationComponent, canActivate : [AuthGuard] },
{ path : 'quiz', component : QuizComponent, canActivate : [AuthGuard] },
{ path : 'add_quiz', component : AddQuizComponent , canActivate : [AuthGuard] },
{ path : 'update_quiz/:id', component : UpdateQuizComponent, canActivate : [AuthGuard] },
{ path : 'e-learning/categories', component : CategoryComponent, canActivate : [AuthGuard]},
{ path : 'e-learning/videos', component : AssignVideoToCategoryComponent,canActivate : [AuthGuard] },
 { path : 'e-learning/videos/:id', component : AssignVideoToCategoryComponent,canActivate : [AuthGuard] },
 { path : 'shop/categories', component : ShopCategoriesComponent,canActivate : [AuthGuard] },
 { path : 'products/:categoryId', component : ProductUnderCategoryComponent,canActivate : [AuthGuard] },
 { path : 'view/product/:id', component : ViewAndUpdateProductComponent,canActivate : [AuthGuard] },
 { path : 'add_product/:name', component : AddProductComponent,canActivate : [AuthGuard] },
 { path : 'add_product', component : AddProductComponent,canActivate : [AuthGuard] },

 { path : 'update_product/:id', component : UpdateProductComponent,canActivate : [AuthGuard] },
 { path : 'order/active', component : ActiveOrdersComponent,canActivate : [AuthGuard] },
 { path : 'order/past', component : CompletedOrdersComponent,canActivate : [AuthGuard] },
 { path : 'order/cancel', component : CancelOrdersComponent,canActivate : [AuthGuard] },
 { path : 'order/return', component : ReturnOrdersComponent,canActivate : [AuthGuard] },

 { path : 'order/active/order_detail/:id', component : OrderDetailComponent,canActivate : [AuthGuard] },
 { path : 'order/past/order_detail/:id', component : OrderDetailComponent,canActivate : [AuthGuard] },
 { path : 'order/cancel/order_detail/:id', component : OrderDetailComponent,canActivate : [AuthGuard] },
 { path : 'order/return/order_detail/:id', component : OrderDetailComponent,canActivate : [AuthGuard] },

 { path : 'create/blog', component : CreateBlogComponent,canActivate : [AuthGuard] },
 { path : 'update/blog/:id', component : UpdateBlogComponent,canActivate : [AuthGuard] },
 { path : 'blogs', component : BlogsComponent,canActivate : [AuthGuard] },
 { path : 'e-learning/Allvideos', component : AllvideosComponent,canActivate : [AuthGuard] },
 { path : 'e-learning/AddVideos', component : AddELearningVideoComponent,canActivate : [AuthGuard] },
 { path : 'e-learning/AddVideos/:id', component : AddELearningVideoComponent,canActivate : [AuthGuard] },

 { path : 'Report', component : ReportManagementComponent,canActivate : [AuthGuard] },
 { path : 'banner', component : BannerManagementComponent,canActivate : [AuthGuard] },
 { path : 'offers', component : OffersManagementComponent,canActivate : [AuthGuard] },
 { path : 'transaction', component : TransactionHistoryComponent,canActivate : [AuthGuard] },
 { path : 'charges', component : ChargesManagementComponent,canActivate : [AuthGuard] },
 { path : 'email', component : EmailManagementComponent,canActivate : [AuthGuard] },
 { path : 'add-offers', component : AddOffersComponent,canActivate : [AuthGuard] },
 { path : 'update-offers/:id', component : UpdateOffersComponent,canActivate : [AuthGuard] },
 
 { path : 'createEmail', component : CreateEmailComponent,canActivate : [AuthGuard] },
 { path : 'view-sent-mail', component : ViewSentEmailComponent,canActivate : [AuthGuard] },

 { path : 'Emails', component : EmailManagementComponent,canActivate : [AuthGuard] },
 { path : 'update-charges/:string', component : EditChargesComponent,canActivate : [AuthGuard] },
 { path : 'subscription', component : SubscriptionComponent,canActivate : [AuthGuard] },
 { path : 'e-learning/UpdateVideos/:id', component : UpdateElearningVideoComponent,canActivate : [AuthGuard] },
 { path : 'address', component : AdminAddressComponent,canActivate : [AuthGuard] },
 { path : 'add-address', component : AddAddressComponent,canActivate : [AuthGuard] },
 { path : 'subscription-details/:id', component : UserSubscriptionDetailComponent,canActivate : [AuthGuard] },
 { path : 'refund/:id', component : RefundComponent,canActivate : [AuthGuard] },
 { path : 'premium-user', component : PremiumUsersComponent,canActivate : [AuthGuard] },
 { path : 'quiz-reward', component : QuizRewardComponent,canActivate : [AuthGuard] },
 { path : 'update-reward', component : UpdateRewardComponent,canActivate : [AuthGuard] },

 { path : 'notifications', component : NotificationComponent,canActivate : [AuthGuard] },
 { path : 'certification_logo', component : LogoCertificationComponent,canActivate : [AuthGuard] },
 { path : 'home', component : HomeComponent,canActivate : [AuthGuard] },
 { path : 'trusted_logo', component : TrustedLogoComponent,canActivate : [AuthGuard] },
 { path : 'testimonials', component : TestimonialsComponent,canActivate : [AuthGuard] },
 { path : 'health_data', component : HealthDataComponent,canActivate : [AuthGuard] },
 { path : 'contact_us', component : ContactUsComponent,canActivate : [AuthGuard] },
 { path : 'professional_accounts', component : ProfessionalAccountsComponent,canActivate : [AuthGuard] },
 { path : 'professional_accounts/:id', component : UserDetailComponent,canActivate : [AuthGuard] },

 { path : 'posts/me', component : MePostsComponent,canActivate : [AuthGuard] },
 { path : 'posts/med', component : MedPostsComponent,canActivate : [AuthGuard] },
 { path : 'posts/world', component : WorldPostsComponent,canActivate : [AuthGuard] },
 { path : 'posts/world/:id', component : WorldPostDetailsComponent,canActivate : [AuthGuard] },

 { path : 'comments/flagged', component : FlaggedCommentsComponent,canActivate : [AuthGuard] },

 { path : 'md/upcoming', component : UpcomingRequestsComponent,canActivate : [AuthGuard] },
 { path : 'md/completed', component : CompletedRequestComponent,canActivate : [AuthGuard] },
 { path : 'md/cancelled', component : CancelledRequestComponent,canActivate : [AuthGuard] },



];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }