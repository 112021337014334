
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          
            <div class="card-body">

                 <form [formGroup] = "formdata" >
                  <div class="form-group">
                    <label for="Name" class="required">Exercise Name:</label>
                    <input type="text"  formControlName='exerciseName' class="form-control" id="inputName" placeholder="Enter Exercise Name" name="Name" [ngClass]="{ 'is-invalid': submitted && f.exerciseName.errors}">
                  </div>
                  <div class="form-group">
                    <label for="pwd" class="required">Duration:</label>
                    <input type="text"  maxlength="3" formControlName='timeDuration' min="1"  (keypress)="numberOnly($event)"   class="form-control" id="inputDuration" placeholder="Minutes" name="duration" [ngClass]="{ 'is-invalid': submitted && f.timeDuration.errors}">
                  </div>
                  <!-- <div class="form-group">
                    <label for="pwd" class="required">Thumbnail: (H: 165px W: 121px)</label>
                    <input type="file" class="form-control" id="thumbnail" (change)="uploadThumbnail($event)">
                  </div> -->
                  <div class="justify-content-center d-flex mt-4">
                  <button type="submit" class="btn btn-submit mr-3" (click)="addExercise(formdata.value)">Submit</button>
                  <button  class="btn btn-danger" routerLink="/exercises" >Cancel</button>
                  </div>
                </form>
            </div>
           
          </div>
        </div>
  