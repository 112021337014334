<div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
            <div class="card-header">
                Flagged Comments
            </div>
            <div class="card-body">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Time</th>
                            <th>Flagged By</th>
                            <th>Flagged User</th>
                            <th>Post Type</th>
                            <th>Comment</th>
                            <th>Action</th>
                            <th>View Post</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comment of comments | paginate: pagination, let i=index">
                            <td>{{ i+1 }}</td>
                            <td>{{ comment.createdAt |	date : 'dd-MM-yyyy hh:mm a' }}</td>
                            <td>{{ comment.user_id?.name }}</td>
                            <td>{{ comment?.comment_id?.comment_by?.firstName }}
                            </td>
                            <td>{{ comment?.comment_id?.post_id?.userType == 'Client' ? 'Me' : 'Med' }}
                            </td>
                            <td class="med-description-clamp">
                                <p class="description-data"> {{ transformMentions(comment.comment_id.comment_text) }}</p>
                                <a *ngIf="comment.comment_id.comment_text.length > 20" (click)="showContent(transformMentions(comment.comment_id.comment_text))"
                                    class="view-data">View more</a>
                            </td>
                            <td>
                                <button class="btn btn-danger ml-2" (click)="deleteComment(comment)" matTooltip="Delete">
                                    <span class="fa fa-trash"></span>
                                </button>
                            </td>
                            
                            <td>
                                <a [href]="webUrl+urlAppend+'?id='+comment.comment_id.post_id._id" target="_blank" class="btn btn-info ml-2"
                                    matTooltip="Navigate to Post">
                                    <span class="fa fa-eye"></span>
                                </a>
                            </td>
                        </tr>
                        <tr *ngIf="comments.length==0">
                            <td colspan="100%">No data found!</td>
                        </tr>
                    </tbody>
                </table>

                <pagination-controls  class="pagination"
                    (pageChange)="onPageChange($event)"
                    [totalItems]="100"
                    [itemsPerPage]="10"
                    [currentPage]="1"
                    >
                </pagination-controls>
            </div>
        </div>
    </div>
</div>
